import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Typography,
  Button,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import { useLimits } from "../context/LimitsContext";
import {
  ArrowUpward as ArrowUpwardIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import profileApi from "../api/profileApi";
import { toast } from "react-toastify";
import { getErrorMessage } from "../utils/translations";

const SidenavCredits = ({ isExpanded }) => {
  const { limits } = useLimits();
  const theme = useTheme();

  if (!limits) return null;

  const {
    subscriptionPlanTypeId,
    planName,
    aiCredits,
    planFeatures,
    nextMonthlyResetDate,
    isLifetime,
    next_payment_date,
  } = limits;

  const formatCredits = (used, total) => `${used}/${total}`;

  const calculatePercentage = (used, total) => (used / total) * 100;

  const formatResetDate = (date) => {
    // Get current date in UTC
    const now = new Date();
    
    let nextReset;
    
    if (isLifetime) {
      // If lifetime subscription, use current/next month's 26th
      nextReset = new Date(Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        26,
        0,
        1  // 00:01 UTC
      ));
      
      // If we're past the 26th, get next month's 26th
      if (now >= nextReset) {
        nextReset = new Date(Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth() + 1,
          26,
          0,
          1
        ));
      }
    } else if (next_payment_date) {
      // If not lifetime and next_payment_date exists, use that
      nextReset = new Date(next_payment_date);
    } else {
      // Fallback to current/next month's 26th if no next_payment_date
      nextReset = new Date(Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        26,
        0,
        1
      ));
      
      if (now >= nextReset) {
        nextReset = new Date(Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth() + 1,
          26,
          0,
          1
        ));
      }
    }

    // Calculate time difference
    const diffTime = nextReset - now;
    const days = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));

    // Create human readable string
    let timeString = [];
    if (days > 0) timeString.push(`${days} day${days !== 1 ? 's' : ''}`);
    if (hours > 0) timeString.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
    if (minutes > 0) timeString.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`);
    
    return `${nextReset.toUTCString().split(' ').slice(0, 4).join(' ')} (in ${timeString.join(', ')})`;
  };

  const handleUpgradeClick = async (e) => {
    e.preventDefault(); // Prevent default Link behavior
    try {
      const url = await profileApi.getWordPressLoginUrl();
      window.open(url, "_blank");
    } catch (error) {
      const errorMessage = getErrorMessage(
        "ERROR_LOADING_BILLING_CONTACT_SUPPORT"
      );
      toast.error(errorMessage);
    }
  };

  return (
    <Box
      sx={{
        p: 2,
        pt: "24px !important",
        pb: "24px !important",
        borderTop: 'none',
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: "#ffffff",
        backdropFilter: "none",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
      }}
    >
      {isExpanded && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: "text.primary", fontWeight: "bold" }}
            >
              Current Plan
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {planName ? planName : "Free/Cancelled"}
            </Typography>
          </Box>
          {![3, 13, 33].includes(subscriptionPlanTypeId) && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleUpgradeClick}
                variant="contained"
                size="small"
                startIcon={<ArrowUpwardIcon />}
                sx={{
                  mb: 2,
                  width: "80%",
                  backgroundColor: (theme) => theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.primary.dark,
                  },
                }}
              >
                Upgrade
              </Button>
            </Box>
          )}
          <Tooltip
            title={`Next monthly credit reset: ${formatResetDate(
              nextMonthlyResetDate
            )}`}
            placement="top"
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "text.primary",
                fontWeight: "bold",
                mb: 1,
                display: "flex",
                alignItems: "center",
                cursor: "help",
              }}
            >
              Credits Reset <RefreshIcon fontSize="small" sx={{ ml: 1 }} />
            </Typography>
          </Tooltip>
          <Box>
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Credits Usage:</span>
              <span>{formatCredits(aiCredits.used, aiCredits.monthly)}</span>
            </Typography>
            <LinearProgress
              variant="determinate"
              value={calculatePercentage(aiCredits.used, aiCredits.monthly)}
              sx={{
                mt: 0.5,
                backgroundColor: "rgba(0,0,0,0.1)",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: (theme) => theme.palette.primary.main,
                },
              }}
            />
          </Box>
        </>
      )}
      {!isExpanded && (
        <Tooltip
          title={`Credits | Next monthly reset date: ${formatResetDate(
            nextMonthlyResetDate
          )}`}
          placement="right"
        >
          <Box
            sx={{
              width: "100%",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "text.primary",
                writingMode: "vertical-rl",
                textOrientation: "mixed",
                transform: "rotate(180deg)",
              }}
            >
              Credits
            </Typography>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default SidenavCredits;
