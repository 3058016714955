import { useEffect } from "react";
import { useBlocker } from "react-router-dom";
import { Box, Button, Paper, Typography } from "@mui/material";

export const useNavigationBlocker = (shouldBlockCondition) => {
  const blocker = useBlocker(shouldBlockCondition);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (shouldBlockCondition()) {
        e.preventDefault();
        e.returnValue =
          "Changes you made may not be saved. Are you sure you want to leave?";
        return e.returnValue;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [shouldBlockCondition]);

  const NavigationBlockerDialog = () => {
    if (blocker.state !== "blocked") return null;

    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            p: 3,
            maxWidth: 400,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Are you sure you want to leave?
          </Typography>
          <Typography sx={{ mb: 3 }}>
            Changes you made may not be saved.
          </Typography>
          <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={() => blocker.reset()}
              sx={{
                bgcolor: "grey.500",
                "&:hover": { bgcolor: "grey.700" },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => blocker.proceed()}
              sx={{
                bgcolor: "#9c27b0",
                "&:hover": { bgcolor: "#7b1fa2" },
              }}
            >
              Leave anyway
            </Button>
          </Box>
        </Paper>
      </Box>
    );
  };

  return NavigationBlockerDialog;
};
