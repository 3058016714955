import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import LanguageSelector from "../common/LanguageSelector";
import generalApi from "../../api/generalApi";

const AddProjectModal = ({ open, onClose, onProjectAdded }) => {
  const [projectData, setProjectData] = useState({
    name: "",
    description: "",
    language: "",
  });
  const [error, setError] = useState({
    name: "",
    description: "",
    language: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setProjectData((prev) => ({
        ...prev,
        language: localStorage.getItem("selectedLanguage") || "en-US",
      }));
    }
  }, [open]);

  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setProjectData((prev) => ({
      ...prev,
      [field]: value,
    }));
    // Save language selection to localStorage when it changes
    if (field === "language") {
      localStorage.setItem("selectedLanguage", value);
    }
    // Clear error when user starts typing
    if (error[field]) {
      setError((prev) => ({
        ...prev,
        [field]: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    const newErrors = {};
    if (!projectData.name.trim()) {
      newErrors.name = "Project name is required";
    }
    if (!projectData.language) {
      newErrors.language = "Language is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
      return;
    }

    try {
      setLoading(true);
      setError({});

      const response = await generalApi.project.createProject({
        name: projectData.name.trim(),
        description: projectData.description.trim(),
        language: projectData.language,
      });

      onProjectAdded((response.data || response)?.results);
    } catch (err) {
      console.error("Error creating project:", err);
      setError({
        name: err.response?.data?.message || "Failed to create project",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setProjectData({
      name: "",
      description: "",
      language: "en-US",
    });
    setError({});
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography variant="h6" component="div">
            Create New Project
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              autoFocus
              label="Project Name"
              fullWidth
              value={projectData.name}
              onChange={handleChange("name")}
              error={!!error.name}
              helperText={error.name}
              disabled={loading}
            />

            <TextField
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={projectData.description}
              onChange={handleChange("description")}
              error={!!error.description}
              helperText={error.description}
              disabled={loading}
            />

            <LanguageSelector
              value={projectData.language}
              onChange={handleChange("language")}
              error={!!error.language}
              helperText={error.language}
              disabled={loading}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{
              bgcolor: "#9c27b0",
              "&:hover": { bgcolor: "#7b1fa2" },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Create Project"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddProjectModal;
