import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Container,
  Tooltip,
} from "@mui/material";
import {
  Image,
  Target,
  MessageSquare,
  Zap,
  Facebook,
  BookOpen,
  ExternalLink,
  MessageCircle,
  Lightbulb,
  Video,
  FileVideo,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

const DashboardCard = ({
  icon: Icon,
  title,
  description,
  buttonText,
  onClick,
  disabled,
  comingSoon,
  comingSoonDate,
  isBeta,
  betaTooltip,
}) => (
  <Card
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      boxShadow: "none",
      border: "1px solid rgba(0, 0, 0, 0.12)",
    }}
  >
    <CardContent
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Icon size={24} style={{ color: "#9c27b0" }} />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {isBeta && (
            <Tooltip title={betaTooltip || "This feature is in beta"}>
              <Typography
                variant="caption"
                sx={{
                  bgcolor: '#9c27b0',
                  color: 'white',
                  px: 0.8,
                  py: 0.3,
                  borderRadius: 1,
                  fontSize: '0.65rem',
                  lineHeight: 1,
                  height: 'fit-content',
                  whiteSpace: 'nowrap',
                  cursor: 'default',
                }}
              >
                BETA
              </Typography>
            </Tooltip>
          )}
          {comingSoon && (
            <Typography
              variant="caption"
              sx={{
                color: "#9c27b0",
                fontWeight: "bold",
              }}
            >
              Coming {comingSoonDate}
            </Typography>
          )}
        </Box>
      </Box>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mb: 3, flexGrow: 1 }}
      >
        {description}
      </Typography>
      <Button
        variant="contained"
        fullWidth
        onClick={onClick}
        disabled={disabled}
        sx={{
          bgcolor: "#9c27b0",
          "&:hover": { bgcolor: "#7b1fa2" },
          textTransform: "none",
          padding: "10px 0",
        }}
      >
        {buttonText}
      </Button>
    </CardContent>
  </Card>
);

const Dashboard = () => {
  const navigate = useNavigate();

  const tools = [
    {
      icon: Lightbulb,
      title: "Blog Post Ideas",
      description:
        "Get blog post ideas that will crush it on social media, helping you create content that resonates with your audience.",
      buttonText: "Generate Ideas",
      onClick: () => navigate("/blog-post-ideas"),
    },
    {
      icon: MessageSquare,
      title: "Social-First Blog Posts",
      description:
        "Generate one or multiple blog posts optimized for social sharing, increasing your content's reach and impact.",
      buttonText: "Create Posts",
      onClick: () => navigate("/social-first"),
    },
    {
      icon: Zap,
      title: "Status Post Generator",
      description:
        "Generate engaging social media posts that capture attention and drive interaction with your audience.",
      buttonText: "Create Status",
      onClick: () => navigate("/status-post-generator"),
    },
    {
      icon: Image,
      title: "Meme Generator",
      description:
        "Generate memes relevant to your niche to maximize engagement and boost your social media presence.",
      buttonText: "Create Meme",
      onClick: () => navigate("/meme-generator"),
    },
    {
      icon: Facebook,
      title: "Facebook Ad Creator",
      description:
        "Generate engaging Facebook ads with images that convert viewers into customers and boost your ROI.",
      buttonText: "Create Ad",
      onClick: () => navigate("/facebook-ad-creator"),
    },
    {
      icon: ExternalLink,
      title: "Traffic Driving Image Post",
      description:
        "Create images with text over to drive traffic to blog posts.",
      buttonText: "Create Image",
      onClick: () => navigate("/traffic-image"),
      disabled: false,
      opacity: 1,
      comingSoon: false,
      isBeta: true,
      betaTooltip: "This module is still experimental, we're improving the user experience for it",
    },
    {
      icon: MessageCircle,
      title: "NSL Chat AI",
      description:
        "Our real-time chat will answer all your niche website or monetization questions, and help you with your niche website.",
      buttonText: "Launch Chat",
      onClick: () => navigate("/nsl-chat"),
    },
    {
      icon: Video,
      title: "Niche Toolbox Training",
      description:
        "Access our video guides and training materials to master all the tools and maximize your content creation potential.",
      buttonText: "Start Learning",
      onClick: () => navigate("/training"),
    },
    {
      icon: BookOpen,
      title: "The Niche Site Social Media Profit Plan Ebook",
      description:
        "A Step-by-Step System To Make Money From Your Niche Site (Without SEO)",
      buttonText: "Read Ebook",
      onClick: () => window.open('https://bow-cafe-266.notion.site/The-Niche-Site-Social-Media-Profit-Plan-19148f77f9d480e78570dc11d9c6d5cd', '_blank'),
    },
    {
      icon: FileVideo,
      title: "Video to Social-First Blog Post",
      description:
        "Transform any video into an engaging blog post optimized for social media sharing and maximum reach.",
      buttonText: "Convert Video",
      onClick: () => navigate("/video-to-blog"),
      disabled: true,
      opacity: 0.5,
      comingSoon: true,
      comingSoonDate: "Soon",
    },
  ];

  return (
    <Container
      maxWidth={false}
      sx={{
        py: 2,
        px: { xs: 2, sm: 2, md: 2 },
        maxWidth: "1800px",
        margin: "0 auto",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <Typography variant="h5" component="h1" sx={{ mb: "20px", mt: "20px" }}>
        Welcome to Niche Toolbox
      </Typography>
      <Grid
        container
        spacing={1.5}
        sx={{
          mx: 0,
          "& .MuiGrid-item": {
            pb: "0 !important",
          },
        }}
      >
        {tools.map((tool, index) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            key={index}
            sx={{
              "& .MuiCard-root": {
                width: "100%",
                maxWidth: "100%",
                opacity: tool.opacity || 1,
                mb: index >= 4 ? 0 : 1,
              },
            }}
          >
            <DashboardCard {...tool} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Dashboard;
