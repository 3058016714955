import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Tooltip,
  TextareaAutosize,
  Autocomplete,
  Switch,
} from "@mui/material";
import { toast } from "react-toastify";
import { Wand2, Download, ImagePlus, Save, Upload } from "lucide-react";
import TrafficImageCanvas from "./TrafficImageCanvas";
import TrafficImageEditor from "./TrafficImageEditor";
import generalApi from "../../api/generalApi";
import LanguageSelector, { languages } from "../common/LanguageSelector";
import AddProjectModal from "../Project/AddProjectModal";
import ProjectSelector from "../Project/ProjectSelector";
import AddIcon from "@mui/icons-material/Add";

const CANVAS_SIZE = 1024;
const CANVAS_HEIGHT = 1024;

const LANGUAGE_STORAGE_KEY = "lastSelectedLanguage";
const PROJECT_STORAGE_KEY = "lastSelectedProject";

const CREDIT_COSTS = {
  TRAFFIC_IMAGE_BG: 4,
  TRAFFIC_IMAGE_INSET: 4,
  TRAFFIC_IMAGE_TEXT: 1,
};

const fontOptions = [
  { value: '"Arial Black", Impact, sans-serif', label: "Impact (Default)" },
  { value: "Arial, sans-serif", label: "Arial" },
  { value: "Comic Sans MS, cursive", label: "Comic Sans" },
  { value: "Times New Roman, serif", label: "Times New Roman" },
  { value: "Courier New, monospace", label: "Courier" },
  { value: "Georgia, serif", label: "Georgia" },
  { value: "Verdana, sans-serif", label: "Verdana" },
  { value: "Helvetica, Arial, sans-serif", label: "Helvetica" },
];

const TrafficImageGenerator = () => {
  // Canvas and editor state
  const canvasRef = useRef(null);
  const [text, setText] = useState("");
  const [niche, setNiche] = useState("");
  const [fontSize, setFontSize] = useState(72);
  const [strokeWidth, setStrokeWidth] = useState(5);
  const [selectedTab, setSelectedTab] = useState("TEXT");
  const [textPosition, setTextPosition] = useState({
    x: CANVAS_SIZE / 2,
    y: CANVAS_HEIGHT * 0.75,
  });

  // Image state
  const [mainImage, setMainImage] = useState("");
  const [insetImage, setInsetImage] = useState("");
  const [insetPosition, setInsetPosition] = useState({ x: 25, y: 25 });
  const [isInsetDragging, setIsInsetDragging] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  // UI state
  const [loading, setLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  // Add these states
  const [language, setLanguage] = useState(
    () => localStorage.getItem(LANGUAGE_STORAGE_KEY) || "en-US"
  );
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  // Add new state for inset size
  const [insetSize, setInsetSize] = useState(20); // 20% of canvas width

  // Add new states
  const [useInsetImage, setUseInsetImage] = useState(true);
  const [uploadedMainImage, setUploadedMainImage] = useState(null);
  const [uploadedInsetImage, setUploadedInsetImage] = useState(null);

  // Add new state
  const [enhanceText, setEnhanceText] = useState(false);
  const [selectedFont, setSelectedFont] = useState('"Arial Black", Impact, sans-serif');

  // Add language change handler
  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    setSelectedProject(null);
    localStorage.setItem(LANGUAGE_STORAGE_KEY, newLanguage);
    localStorage.removeItem(PROJECT_STORAGE_KEY);
  };

  // Add this effect to fetch projects
  useEffect(() => {
    fetchProjects();
  }, [language]);

  const fetchProjects = async () => {
    try {
      setProjectsLoading(true);
      const response = await generalApi.project.getProjectsLists();
      const filteredProjects = (response.results || []).filter(
        (project) => project.language === language
      );
      setProjects(filteredProjects);

      const savedProjectId = localStorage.getItem(PROJECT_STORAGE_KEY);
      if (savedProjectId) {
        const savedProject = filteredProjects.find(
          (p) => p._id === savedProjectId
        );
        if (savedProject) {
          setSelectedProject(savedProject);
        } else {
          localStorage.removeItem(PROJECT_STORAGE_KEY);
        }
      }
    } catch (err) {
      toast.error("Failed to load projects");
    } finally {
      setProjectsLoading(false);
    }
  };

  // Add this handler
  const handleProjectAdded = (newProject) => {
    setProjects((prev) => [...prev, newProject]);
    setSelectedProject(newProject);
    localStorage.setItem(PROJECT_STORAGE_KEY, newProject._id);
    setIsAddModalOpen(false);
  };

  // Update the image upload handlers
  const handleMainImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedMainImage(reader.result);
        // Don't set mainImage here anymore
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInsetImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedInsetImage(reader.result);
        // Don't set insetImage here anymore
      };
      reader.readAsDataURL(file);
    }
  };

  // Update handleGenerate to handle both AI and uploaded images
  const handleGenerate = async () => {
    if (!text || !niche || !selectedProject) {
      toast.error("Please fill in all required fields");
      return;
    }

    setLoading(true);
    try {
      const response = await generalApi.trafficImage.generateImage(
        text,
        niche,
        selectedProject._id,
        useInsetImage,
        !!uploadedMainImage,
        !!uploadedInsetImage,
        enhanceText
      );

      setMainImage(uploadedMainImage || response.mainImageBase64);
      if (useInsetImage) {
        setInsetImage(uploadedInsetImage || response.insetImageBase64);
      }

      // If text was enhanced, update the text field with the enhanced version
      if (enhanceText && response.enhancedText) {
        setText(response.enhancedText);
      }

      setSelectedTab("TEXT");
      toast.success("Images generated successfully!");
    } catch (error) {
      toast.error(error.message || "Failed to generate images");
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    if (!canvasRef.current) return;
    setIsDownloading(true);

    try {
      const link = document.createElement("a");
      link.download = "traffic-image.png";
      link.href = canvasRef.current.getCanvas().toDataURL("image/png");
      link.click();
    } finally {
      setIsDownloading(false);
    }
  };

  const handleRegenerate = () => handleGenerate();

  const handleSaveToProject = async () => {
    if (!canvasRef.current) return;
    setIsUploading(true);

    try {
      const canvas = canvasRef.current;
      const blob = await new Promise((resolve) =>
        canvas.getCanvas().toBlob(resolve, "image/png")
      );
      const file = new File([blob], "traffic-image.png", { type: "image/png" });

      await generalApi.images.uploadImage(
        file,
        selectedProject._id,
        "trafficImage",
        text
      );
      toast.success("Image saved to project!");
    } catch (error) {
      toast.error(error.message || "Failed to save to project");
    } finally {
      setIsUploading(false);
    }
  };

  // Add these handlers after the existing state declarations
  const handleMouseDown = (e) => {
    const canvas = canvasRef.current?.getCanvas();
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const scaleX = CANVAS_SIZE / rect.width;
    const scaleY = CANVAS_HEIGHT / rect.height;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;

    // Check if click is on inset image
    const insetX = (CANVAS_SIZE * insetPosition.x) / 100;
    const insetY = (CANVAS_HEIGHT * insetPosition.y) / 100;
    const insetSize = CANVAS_SIZE * 0.2;
    const distance = Math.sqrt(
      Math.pow(x - insetX, 2) + Math.pow(y - insetY, 2)
    );

    if (distance <= insetSize / 2) {
      setIsInsetDragging(true);
    } else {
      // Check if click is on text (use a larger hit area)
      const textHitArea = fontSize * 2;
      const textDistance = Math.sqrt(
        Math.pow(x - textPosition.x, 2) + Math.pow(y - textPosition.y, 2)
      );
      if (textDistance <= textHitArea) {
        setIsDragging(true);
      }
    }
  };

  const handleMouseMove = (e) => {
    if (!isInsetDragging && !isDragging) return;

    const canvas = canvasRef.current?.getCanvas();
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const scaleX = CANVAS_SIZE / rect.width;
    const scaleY = CANVAS_HEIGHT / rect.height;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;

    if (isInsetDragging) {
      // Update inset position as percentage
      setInsetPosition({
        x: Math.max(10, Math.min(90, (x / CANVAS_SIZE) * 100)),
        y: Math.max(10, Math.min(90, (y / CANVAS_HEIGHT) * 100)),
      });
    } else if (isDragging) {
      // Update text position in pixels
      setTextPosition({
        x: Math.max(CANVAS_SIZE * 0.1, Math.min(CANVAS_SIZE * 0.9, x)),
        y: Math.max(CANVAS_HEIGHT * 0.1, Math.min(CANVAS_HEIGHT * 0.9, y)),
      });
    }
  };

  const handleMouseUp = () => {
    setIsInsetDragging(false);
    setIsDragging(false);
  };

  // Create the editor props object
  const editorProps = {
    text,
    onTextChange: setText,
    fontSize,
    onFontSizeChange: setFontSize,
    strokeWidth,
    onStrokeWidthChange: setStrokeWidth,
    selectedTab,
    onTabChange: setSelectedTab,
    insetPosition,
    onInsetPositionChange: setInsetPosition,
    insetSize,
    onInsetSizeChange: setInsetSize,
    selectedFont,
    onFontChange: setSelectedFont,
    fontOptions,
    useInsetImage,
    onUseInsetImageChange: (checked) => setUseInsetImage(checked),
  };

  // Create the canvas props object
  const canvasProps = {
    ref: canvasRef,
    mainImage,
    insetImage,
    useInsetImage,
    text,
    textPosition,
    fontSize,
    strokeWidth,
    insetPosition,
    insetSize,
    isImageDragging: isInsetDragging || isDragging,
    onMouseDown: handleMouseDown,
    onMouseMove: handleMouseMove,
    onMouseUp: handleMouseUp,
    onMouseLeave: handleMouseUp,
    selectedFont,
  };

  const calculateCreditCost = useCallback(() => {
    // Base cost of 1 credit for any generation
    let totalCost = CREDIT_COSTS.TRAFFIC_IMAGE_TEXT;

    // Add cost for AI background if not uploaded
    if (!uploadedMainImage) {
      totalCost += CREDIT_COSTS.TRAFFIC_IMAGE_BG;
    }

    // Add cost for AI inset if enabled and not uploaded
    if (useInsetImage && !uploadedInsetImage) {
      totalCost += CREDIT_COSTS.TRAFFIC_IMAGE_INSET;
    }

    return totalCost;
  }, [uploadedMainImage, useInsetImage, uploadedInsetImage]);

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "calc(100vh - 84px)",
        py: 2,
        px: { xs: 2, sm: 4, md: 6 },
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header with title and action buttons */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          gap: { xs: 2, sm: 0 },
          mb: 4,
        }}
      >
        <Box>
          <Typography
            variant="h4"
            component="h1"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "1.5rem", sm: "1.5rem" },
            }}
          >
            Traffic Driving Image Post Generator
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "text.secondary",
              mt: 0.5,
              fontSize: { xs: "0.875rem", sm: "1rem" },
            }}
          >
            Create images with text over to drive traffic to blog posts.
          </Typography>
        </Box>

        {(mainImage || insetImage) && (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="outlined"
              onClick={handleDownload}
              disabled={isDownloading}
              startIcon={<Download />}
            >
              Download
            </Button>
            <Button
              variant="outlined"
              onClick={handleSaveToProject}
              disabled={isUploading}
              startIcon={<Save />}
            >
              Save to Project
            </Button>
            <Button
              variant="contained"
              onClick={handleRegenerate}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : <Wand2 />}
              sx={{
                bgcolor: "#9c27b0",
                "&:hover": { bgcolor: "#7b1fa2" },
              }}
            >
              Regenerate
            </Button>
          </Box>
        )}
      </Box>

      {/* Main content grid */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
          gap: { xs: 2, md: 4 },
          flex: 1,
          width: "100%",
          minHeight: 0,
        }}
      >
        {/* Left Panel */}
        <Box
          sx={{
            bgcolor: "#ffffff",
            borderRadius: 3,
            p: { xs: 2, md: 4 },
            pt: { xs: "12px !important", md: "16px !important" },
            height: "auto",
            width: "100%",
            order: { xs: 2, md: 1 },
            boxShadow: "none",
            border: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          {!mainImage ? (
            // Initial form
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                  Language
                </Typography>
                <LanguageSelector
                  value={language}
                  onChange={handleLanguageChange}
                  sx={{
                    bgcolor: "#fff",
                    "& .MuiSelect-select": {
                      bgcolor: "#fff",
                    },
                  }}
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                  Project
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Autocomplete
                    value={selectedProject}
                    onChange={(_, newValue) => {
                      setSelectedProject(newValue);
                      if (newValue?._id) {
                        localStorage.setItem(PROJECT_STORAGE_KEY, newValue._id);
                      }
                    }}
                    options={projects}
                    getOptionLabel={(option) => option.name || ""}
                    loading={projectsLoading}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select a project"
                        sx={{
                          bgcolor: "#fff",
                          "& .MuiOutlinedInput-root": {
                            bgcolor: "#fff",
                          },
                        }}
                      />
                    )}
                  />
                  <Button
                    variant="outlined"
                    onClick={() => setIsAddModalOpen(true)}
                    sx={{ minWidth: "auto", px: 1 }}
                  >
                    <AddIcon />
                  </Button>
                </Box>
              </Box>

              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                  Niche
                </Typography>
                <TextField
                  fullWidth
                  value={niche}
                  onChange={(e) => setNiche(e.target.value)}
                  placeholder="Enter your niche (e.g., 'fitness', 'cooking')"
                />
              </Box>

              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                  Background Image
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    variant="outlined"
                    component="label"
                    startIcon={<Upload />}
                    sx={{ flex: 1 }}
                  >
                    Upload Image
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleMainImageUpload}
                    />
                  </Button>
                  {uploadedMainImage && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => setUploadedMainImage(null)}
                    >
                      Clear
                    </Button>
                  )}
                </Box>
              </Box>

              <Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    Inset Image
                  </Typography>
                  <Switch
                    checked={useInsetImage}
                    onChange={(e) => setUseInsetImage(e.target.checked)}
                    size="small"
                  />
                </Box>
                {useInsetImage && (
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      variant="outlined"
                      component="label"
                      startIcon={<Upload />}
                      sx={{ flex: 1 }}
                    >
                      Upload Image
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleInsetImageUpload}
                      />
                    </Button>
                    {uploadedInsetImage && (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setUploadedInsetImage(null)}
                      >
                        Clear
                      </Button>
                    )}
                  </Box>
                )}
              </Box>

              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                  Blog Post Title Text To Include in Image
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Enter the text to display on the image"
                  sx={{ mb: 1 }}
                />
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch
                    checked={enhanceText}
                    onChange={(e) => setEnhanceText(e.target.checked)}
                    size="small"
                  />
                  <Typography variant="body2" color="text.secondary">
                    Enhance Text With Our AI
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                <Tooltip title={`${calculateCreditCost()} Credits`} arrow>
                  <Button
                    variant="contained"
                    onClick={handleGenerate}
                    disabled={loading || !text || !niche || !selectedProject}
                    startIcon={
                      loading ? <CircularProgress size={20} /> : <Wand2 />
                    }
                    sx={{
                      py: 1.5,
                      px: 4,
                      bgcolor: "#9c27b0",
                      "&:hover": { bgcolor: "#7b1fa2" },
                    }}
                  >
                    Generate Image
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          ) : (
            // Editor controls after generation
            <TrafficImageEditor {...editorProps} />
          )}
        </Box>

        {/* Right Panel - Preview */}
        <Box
          sx={{
            bgcolor: "#ffffff",
            borderRadius: 3,
            p: { xs: 2, md: 4 },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            order: { xs: 1, md: 2 },
            boxShadow: "none",
            border: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          {mainImage ? (
            <TrafficImageCanvas {...canvasProps} />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <ImagePlus
                size={48}
                style={{
                  color: "#666666",
                  marginBottom: "16px",
                  opacity: 0.7,
                }}
              />
              <Typography
                color="text.secondary"
                variant="h6"
                sx={{ fontSize: { xs: "1rem", md: "1.25rem" } }}
              >
                Generated image will appear here
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* Add Project Modal */}
      <AddProjectModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onProjectAdded={handleProjectAdded}
      />
    </Container>
  );
};

export default TrafficImageGenerator;
