import React, { useState, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  TextareaAutosize,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import {
  Upload,
  Wand2,
  Download,
  PlusCircle,
  Image,
  ImagePlus,
  Sparkles,
} from "lucide-react";
import generalApi from "../../../api/generalApi";
import { toast } from "react-toastify";
import ProjectSelector from "../../Project/ProjectSelector";
import { useLimits } from "../../../context/LimitsContext";
import { useNavigationBlocker } from "../../../hooks/useNavigationBlocker";
import LanguageSelector, { languages } from "../../common/LanguageSelector";
import FacebookAdUploadEditor from "./FacebookAdUploadEditor";
import FacebookAdUploadCanvas from "./FacebookAdUploadCanvas";

// Define fontOptions outside the component
const fontOptions = [
  { value: '"Arial Black", Impact, sans-serif', label: "Impact (Default)" },
  { value: "Arial, sans-serif", label: "Arial" },
  { value: "Comic Sans MS, cursive", label: "Comic Sans" },
  { value: "Times New Roman, serif", label: "Times New Roman" },
  { value: "Courier New, monospace", label: "Courier" },
  { value: "Georgia, serif", label: "Georgia" },
  { value: "Verdana, sans-serif", label: "Verdana" },
  { value: "Helvetica, Arial, sans-serif", label: "Helvetica" },
];

const CANVAS_SIZE = 600;
const TOP_HEIGHT = CANVAS_SIZE * 0.18; // 18% of canvas height
const BOTTOM_HEIGHT = CANVAS_SIZE * 0.12; // 12% of canvas height

const FacebookAdUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [memeText, setMemeText] = useState(() => {
    return localStorage.getItem("fbAdIdea") || "";
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState(() => {
    // Initialize with value from localStorage or default to 'en-US'
    return localStorage.getItem("selectedLanguage") || "en-US";
  });
  const [generatedMeme, setGeneratedMeme] = useState(null);
  const [fontSize, setFontSize] = useState(50);
  const [textAreaWidth, setTextAreaWidth] = useState(100);
  const [strokeWidth, setStrokeWidth] = useState(0);
  const [textFillColor, setTextFillColor] = useState("#000000");
  const [textStrokeColor, setTextStrokeColor] = useState("#000000");
  const [selectedFont, setSelectedFont] = useState(
    "Helvetica, Arial, sans-serif"
  );
  const [textPosition, setTextPosition] = useState({ x: 0, y: 0 });
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [memeDescription, setMemeDescription] = useState("");
  const [projectDialogOpen, setProjectDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { limits, fetchLimits } = useLimits();
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [bottomText, setBottomText] = useState(() => {
    const options = [
      "Tap here",
      "Tap if you agree",
      "Press here",
      "Press if you agree",
    ];
    return options[Math.floor(Math.random() * options.length)];
  });
  const [bottomFontSize, setBottomFontSize] = useState(50);
  const [bottomTextAreaWidth, setBottomTextAreaWidth] = useState(100);
  const [bottomStrokeWidth, setBottomStrokeWidth] = useState(0);
  const [bottomTextFillColor, setBottomTextFillColor] = useState("#000000");
  const [bottomTextStrokeColor, setBottomTextStrokeColor] = useState("#000000");
  const [bottomFont, setBottomFont] = useState("Helvetica, Arial, sans-serif");
  const [bottomTextPosition, setBottomTextPosition] = useState({ x: 0, y: 0 });
  const [selectedTab, setSelectedTab] = useState(null);
  const [imageOffsetY, setImageOffsetY] = useState(0);
  const [emojiSize, setEmojiSize] = useState(100);
  const [emojiPosition, setEmojiPosition] = useState({
    x: CANVAS_SIZE - 20, // 20px from right edge
    y: CANVAS_SIZE - 60, // 60px from bottom edge
  });
  const [customEmoji, setCustomEmoji] = useState("👇");
  const [topTextHeight, setTopTextHeight] = useState(18);
  const [bottomTextHeight, setBottomTextHeight] = useState(12);
  const [isEmojiDragging, setIsEmojiDragging] = useState(false);
  const [imageScale, setImageScale] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false);
  const [imagePosition, setImagePosition] = useState(null);
  const [niche, setNiche] = useState(() => {
    return localStorage.getItem("fbAdNiche") || "";
  });
  const [imageGenerationType, setImageGenerationType] = useState("upload");
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [watermark, setWatermark] = useState(null);

  const shouldBlockNavigation = useCallback(() => {
    return isGenerating || isLoading;
  }, [isGenerating, isLoading]);

  const NavigationBlockerDialog = useNavigationBlocker(shouldBlockNavigation);

  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileSelection(file);
    }
  };

  const handleFileSelection = (file) => {
    if (file) {
      // Clear previous file and preview
      setSelectedFile(null);
      setPreviewUrl(null);

      // Validate file
      if (!file.type.match(/image\/(jpeg|png|gif)/i)) {
        setError("Please upload a valid image file (JPEG, PNG, or GIF)");
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        setError("File size should be less than 2MB");
        return;
      }

      // Create new preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setSelectedFile(file);
        setError(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const getLanguageName = (code) => {
    const language = languages.find((lang) => lang.code === code);
    return language ? language.name : "English";
  };

  const handleMemeTextChange = (value) => {
    setMemeText(value);
    localStorage.setItem("fbAdIdea", value);
  };

  const handleNicheChange = (value) => {
    setNiche(value);
    localStorage.setItem("fbAdNiche", value);
  };

  const generateAd = async ({
    base64Image,
    niche,
    memeText,
    language,
    watermark,
    imageGenerationType,
    isRegenerating = false,
  }) => {
    let requestData = {
      isAiImageGeneration: imageGenerationType === "ai",
      imageBase64: base64Image,
      niche: niche.trim(),
      prompt: memeText.trim(),
      language: getLanguageName(language),
    };

    if (watermark) {
      const watermarkBase64 = await convertToBase64(watermark);
      requestData.watermark = watermarkBase64;
    }

    const response = await generalApi.facebookAds.createAd(requestData);

    // If AI generated image, create a blob from base64 and set it as selected file
    if (response.imageBase64) {
      setPreviewUrl(response.imageBase64);
      const blob = await fetch(response.imageBase64).then((r) => r.blob());
      const file = new File([blob], "ai-generated.png", {
        type: "image/png",
      });
      setSelectedFile(file);
    }

    setGeneratedMeme({
      memeText: {
        bestUniqueTake: response.ad,
      },
    });

    if (!isRegenerating) {
      // Only set these for initial generation
      const options = [
        "Tap here",
        "Tap if you agree",
        "Press here",
        "Press if you agree",
      ];
      setBottomText(options[Math.floor(Math.random() * options.length)]);

      setImagePosition({ x: 0, y: 0 });
      setImageScale(1);
      setEmojiPosition({ x: CANVAS_SIZE - 20, y: CANVAS_SIZE - 60 });
      setSelectedTab("TOP_TEXT");
    }

    toast.success(
      isRegenerating
        ? "Ad regenerated successfully!"
        : "Ad created successfully!"
    );
  };

  const handleGenerate = async () => {
    if (imageGenerationType === "upload" && !selectedFile) {
      setError("Please provide an image");
      return;
    }

    if (!niche.trim()) {
      setError("Please enter your niche");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      let base64Image = null;
      if (imageGenerationType === "upload") {
        base64Image = await convertToBase64(selectedFile);
      }

      setTimeout(() => {
        fetchLimits();
      }, 5000);

      await generateAd({
        base64Image,
        niche,
        memeText,
        language,
        watermark,
        imageGenerationType,
      });

      // Force a redraw after a short delay to ensure text renders
      setTimeout(() => {
        if (canvasRef.current) {
          canvasRef.current.drawCanvasForDownload(false);
        }
      }, 100);
    } catch (err) {
      console.error("Generation error:", err);
      setError(err.message || "Failed to generate ad");
      toast.error(err.message || "Failed to generate ad");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async () => {
    if (!canvasRef.current) return;

    setIsDownloading(true); // Set flag before drawing

    // Use the special download drawing method
    canvasRef.current.drawCanvasForDownload();

    // Create and trigger download
    const link = document.createElement("a");
    link.download = "facebook-ad.png";
    link.href = canvasRef.current.getCanvas().toDataURL("image/png");
    link.click();

    setIsDownloading(false); // Reset flag after download

    // Force a redraw with the normal view
    canvasRef.current.drawCanvasForDownload(false); // Pass false to indicate normal drawing
  };

  const handleNewMeme = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setMemeText("");
    setMemeDescription("");
    setGeneratedMeme(null);
    setError(null);
    setWatermark(null);
  };

  const handleCreateVersion = async () => {
    setIsGenerating(true);
    try {
      const base64Image = await convertToBase64(selectedFile);
      let requestData = {
        isAiImageGeneration: false,
        imageBase64: base64Image,
        niche: niche.trim(),
        prompt: memeText.trim(),
        language: getLanguageName(language),
      };

      if (watermark) {
        const watermarkBase64 = await convertToBase64(watermark);
        requestData.watermark = watermarkBase64;
      }

      const response = await generalApi.facebookAds.createAd(requestData);
      setGeneratedMeme(response);
      if (response.imageBase64) {
        setPreviewUrl(response.imageBase64);
      }

      await fetchLimits();
      toast.success("New version generated!");
    } catch (err) {
      console.error("Generation error:", err);
      toast.error(err.message || "Failed to generate new version");
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    handleFileSelection(file);
  }, []);

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    handleFileSelection(file);
    // Reset the input value to allow selecting the same file again
    e.target.value = "";
  };

  const handleAddToProject = () => {
    setProjectDialogOpen(true);
  };

  const handleProjectSelect = async (project) => {
    if (!project || !canvasRef.current) {
      return;
    }

    try {
      setIsUploading(true);

      // Convert canvas to blob
      const canvas = canvasRef.current;
      const blob = await new Promise((resolve) =>
        canvas.getCanvas().toBlob(resolve, "image/png")
      );

      // Create a File object from the blob
      const file = new File([blob], "meme.png", { type: "image/png" });

      // Call the upload API
      await generalApi.images.uploadImage(file, project._id, "fbAd", memeText);

      toast.success(`Added to project: ${project.name}`);
      setProjectDialogOpen(false); // Close dialog only after successful upload
    } catch (error) {
      console.error("Error uploading to project:", error);
      toast.error(error.message || "Failed to add to project");
    } finally {
      setIsUploading(false);
    }
  };

  const handleEmojiPositionChange = (newPosition) => {
    setEmojiPosition(newPosition);
  };

  const handleImagePositionChange = (newPosition) => {
    setImagePosition(newPosition);
  };

  const handleRegenerate = async () => {
    setIsRegenerating(true);
    setError(null);

    try {
      let base64Image = null;
      if (imageGenerationType === "upload" && selectedFile) {
        base64Image = await convertToBase64(selectedFile);
      }

      setTimeout(() => {
        fetchLimits();
      }, 5000);

      await generateAd({
        base64Image,
        niche,
        memeText,
        language,
        watermark,
        imageGenerationType,
        isRegenerating: true,
      });

      // Force a redraw after a short delay to ensure text renders
      setTimeout(() => {
        if (canvasRef.current) {
          canvasRef.current.drawCanvasForDownload(false);
        }
      }, 100);
    } catch (err) {
      console.error("Regeneration error:", err);
      setError(err.message || "Failed to regenerate ad");
      toast.error(err.message || "Failed to regenerate ad");
    } finally {
      setIsRegenerating(false);
    }
  };

  // Create the editor props object
  const editorProps = {
    from: "FacebookAdUpload",
    memeText: generatedMeme?.memeText?.bestUniqueTake || "",
    memeDescription,
    fontSize,
    textAreaWidth,
    strokeWidth,
    textFillColor,
    textStrokeColor,
    selectedFont,
    fontOptions,
    backgroundColor,
    onMemeTextChange: (text) =>
      setGeneratedMeme((prev) => ({
        ...prev,
        memeText: { ...prev.memeText, bestUniqueTake: text },
      })),
    onMemeDescriptionChange: (text) => setMemeDescription(text),
    onFontSizeChange: (size) => setFontSize(size),
    onTextAreaWidthChange: (width) => setTextAreaWidth(width),
    onStrokeWidthChange: (width) => setStrokeWidth(width),
    onTextFillColorChange: (color) => setTextFillColor(color),
    onTextStrokeColorChange: (color) => setTextStrokeColor(color),
    onFontChange: (font) => setSelectedFont(font),
    onBackgroundColorChange: (color) => setBackgroundColor(color),
    bottomText,
    bottomFontSize,
    bottomTextAreaWidth,
    bottomStrokeWidth,
    bottomTextFillColor,
    bottomTextStrokeColor,
    bottomFont,
    onBottomTextChange: setBottomText,
    onBottomFontSizeChange: setBottomFontSize,
    onBottomTextAreaWidthChange: setBottomTextAreaWidth,
    onBottomStrokeWidthChange: setBottomStrokeWidth,
    onBottomTextFillColorChange: setBottomTextFillColor,
    onBottomTextStrokeColorChange: setBottomTextStrokeColor,
    onBottomFontChange: setBottomFont,
    generatedMeme,
    selectedTab,
    onTabChange: setSelectedTab,
    imageOffsetY,
    onImageOffsetYChange: setImageOffsetY,
    imageScale,
    onImageScaleChange: setImageScale,
    emojiSize,
    onEmojiSizeChange: setEmojiSize,
    customEmoji,
    onCustomEmojiChange: setCustomEmoji,
    topTextHeight,
    onTopTextHeightChange: setTopTextHeight,
    bottomTextHeight,
    onBottomTextHeightChange: setBottomTextHeight,
    CANVAS_SIZE,
    emojiPosition,
    onEmojiPositionChange: handleEmojiPositionChange,
    imagePosition,
    onImagePositionChange: handleImagePositionChange,
    TOP_HEIGHT,
    BOTTOM_HEIGHT,
  };

  // Create the canvas props object
  const canvasProps = {
    ref: canvasRef,
    imageRef: imageRef,
    preview: previewUrl,
    memeText: generatedMeme?.memeText?.bestUniqueTake || "",
    textPosition,
    fontSize,
    textAreaWidth,
    strokeWidth,
    textFillColor,
    textStrokeColor,
    selectedFont,
    backgroundColor,
    onTextPositionChange: (newPosition) => setTextPosition(newPosition),
    bottomText,
    bottomTextPosition,
    bottomFontSize,
    bottomTextAreaWidth,
    bottomStrokeWidth,
    bottomTextFillColor,
    bottomTextStrokeColor,
    bottomFont,
    onBottomTextPositionChange: setBottomTextPosition,
    generatedMeme,
    selectedTab,
    emojiPosition,
    onEmojiPositionChange: handleEmojiPositionChange,
    emojiSize,
    customEmoji,
    topTextHeight,
    bottomTextHeight,
    isEmojiDragging,
    onEmojiDragStart: () => setIsEmojiDragging(true),
    onEmojiDragEnd: () => setIsEmojiDragging(false),
    isDownloading,
    imagePosition,
    onImagePositionChange: handleImagePositionChange,
    imageScale,
    onImageScaleChange: setImageScale,
    TOP_HEIGHT,
    BOTTOM_HEIGHT,
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "calc(100vh - 84px)",
        py: 2,
        px: { xs: 2, sm: 4, md: 6 },
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header with title and action buttons */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          gap: { xs: 2, sm: 0 },
          mb: 4,
          width: "100%",
        }}
      >
        <Box>
          <Typography
            variant="h4"
            component="h1"
            sx={{
              fontWeight: "bold",
              mt: 2,
              fontSize: { xs: "1.5rem", sm: "1.5rem" },
            }}
          >
            Facebook Ad Creator
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "text.secondary",
              mt: 0.5,
              fontSize: { xs: "0.875rem", sm: "1rem" },
            }}
          >
            Create highly-converting 'Page Like' ads that will grow your
            Facebook fans for less
          </Typography>
        </Box>

        {generatedMeme && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              width: { xs: "100%", sm: "auto" },
              flexWrap: "wrap",
            }}
          >
            <Button
              size="small"
              variant="contained"
              onClick={handleDownload}
              startIcon={<Download size={18} />}
              sx={{
                bgcolor: "#9c27b0",
                "&:hover": { bgcolor: "#7b1fa2" },
                textTransform: "none",
              }}
            >
              Download
            </Button>

            <Button
              size="small"
              variant="outlined"
              onClick={handleAddToProject}
              sx={{
                color: "#9c27b0",
                borderColor: "#9c27b0",
                bgcolor: "#fff",
                "&:hover": {
                  bgcolor: "rgba(156, 39, 176, 0.04)",
                  borderColor: "#7b1fa2",
                },
                textTransform: "none",
                "&.Mui-disabled": {
                  borderColor: "rgba(156, 39, 176, 0.3)",
                  color: "rgba(156, 39, 176, 0.3)",
                },
              }}
            >
              Add to Project
            </Button>

            <Button
              size="small"
              variant="outlined"
              onClick={handleNewMeme}
              startIcon={<PlusCircle size={18} />}
              sx={{
                color: "#9c27b0",
                borderColor: "#9c27b0",
                bgcolor: "#fff",
                "&:hover": {
                  bgcolor: "rgba(156, 39, 176, 0.04)",
                  borderColor: "#7b1fa2",
                },
                textTransform: "none",
              }}
            >
              New
            </Button>

            <ProjectSelector
              open={projectDialogOpen}
              onClose={() => setProjectDialogOpen(false)}
              onProjectSelect={handleProjectSelect}
              onAddProject={() => {
                setProjectDialogOpen(false);
                // Handle new project creation
              }}
              isUploading={isUploading}
            />
          </Box>
        )}
      </Box>

      {/* Main content area */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            md: "1fr 1fr",
          },
          gap: { xs: 2, md: 4 },
          flex: 1,
          width: "100%",
          minHeight: 0,
        }}
      >
        {/* Left Panel */}
        <Box
          sx={{
            bgcolor: "#ffffff",
            borderRadius: 3,
            p: { xs: 2, md: 4 },
            height: "auto",
            minHeight: 0,
            width: "100%",
            order: { xs: 2, md: 1 },
            boxShadow: "none",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!generatedMeme ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                minHeight: 0,
                overflow: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box sx={{ mb: 2, mt: 4 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, fontWeight: 500 }}
                  >
                    Image Source <span style={{ color: "#d32f2f" }}>*</span>
                  </Typography>

                  <FormControl component="fieldset" sx={{ mb: 2 }}>
                    <RadioGroup
                      value={imageGenerationType}
                      onChange={(e) => {
                        setImageGenerationType(e.target.value);
                        setSelectedFile(null);
                        setPreviewUrl(null);
                        setError(null);
                      }}
                    >
                      <FormControlLabel
                        value="upload"
                        control={<Radio />}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <ImagePlus size={18} />
                            <span>Upload Image (3 Credits)</span>
                          </Box>
                        }
                      />
                      <FormControlLabel
                        value="ai"
                        control={<Radio />}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Sparkles size={18} />
                            <span>AI Generated Image (10 Credits)</span>
                          </Box>
                        }
                      />
                    </RadioGroup>
                  </FormControl>

                  {imageGenerationType === "upload" && (
                    <Box
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      sx={{
                        border: "2px dashed",
                        borderColor: "divider",
                        borderRadius: 2,
                        p: 3,
                        textAlign: "center",
                        bgcolor: "#fff",
                        cursor: "pointer",
                        transition: "all 0.2s ease",
                        "&:hover": {
                          borderColor: "#9c27b0",
                          bgcolor: "rgba(156, 39, 176, 0.04)",
                        },
                      }}
                    >
                      <input
                        type="file"
                        id="file-upload"
                        hidden
                        accept="image/png,image/jpeg,image/gif"
                        onChange={handleFileSelect}
                      />
                      <label
                        htmlFor="file-upload"
                        style={{ cursor: "pointer", display: "block" }}
                      >
                        <Upload
                          size={32}
                          style={{
                            color: "#9c27b0",
                            marginBottom: "12px",
                            marginTop: "15px",
                          }}
                        />
                        <Typography
                          variant="body1"
                          sx={{ mb: 1, color: "text.primary" }}
                        >
                          Drag and drop your image here
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          or click to browse
                        </Typography>
                      </label>
                    </Box>
                  )}

                  {error && (
                    <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                      {error}
                    </Typography>
                  )}
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, fontWeight: 500 }}
                  >
                    Niche <span style={{ color: "#d32f2f" }}>*</span>
                  </Typography>
                  <TextareaAutosize
                    value={niche}
                    onChange={(e) => handleNicheChange(e.target.value)}
                    placeholder="Your Niche"
                    style={{
                      width: "100%",
                      padding: "12px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      minHeight: "40px",
                      maxHeight: "40px",
                      resize: "none",
                      backgroundColor: "#fff",
                    }}
                  />
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, fontWeight: 500 }}
                  >
                    Additional Instructions
                  </Typography>
                  <TextareaAutosize
                    value={memeText}
                    onChange={(e) => handleMemeTextChange(e.target.value)}
                    placeholder="Give the AI more details on what you want your Facebook ad to be about"
                    style={{
                      width: "100%",
                      padding: "12px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      minHeight: "40px",
                      maxHeight: "120px",
                      resize: "none",
                      backgroundColor: "#fff",
                    }}
                  />
                </Box>

                <Box sx={{ mb: 4 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, fontWeight: 500 }}
                  >
                    Language <span style={{ color: "#d32f2f" }}>*</span>
                  </Typography>
                  <LanguageSelector
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    sx={{
                      bgcolor: "#fff",
                      "& .MuiSelect-select": {
                        bgcolor: "#fff",
                      },
                    }}
                  />
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, fontWeight: 500 }}
                  >
                    Logo Watermark (Optional)
                  </Typography>
                  <Box
                    component="label"
                    htmlFor="watermark-upload"
                    sx={{
                      border: "2px dashed",
                      borderColor: "divider",
                      borderRadius: 2,
                      p: 3,
                      textAlign: "center",
                      bgcolor: "#fff",
                      transition: "all 0.2s ease",
                      cursor: "pointer",
                      width: "100%",
                      minHeight: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      "&:hover": {
                        borderColor: "#9c27b0",
                        bgcolor: "rgba(156, 39, 176, 0.04)",
                      },
                    }}
                  >
                    <input
                      type="file"
                      hidden
                      accept="image/png,image/jpeg"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setWatermark(file);
                        }
                        e.target.value = "";
                      }}
                      id="watermark-upload"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mt: 2,
                        color: "#9c27b0",
                        borderRadius: 1,
                        pt: 2,
                        px: 2,
                        "&:hover": {
                          borderColor: "#7b1fa2",
                          bgcolor: "rgba(156, 39, 176, 0.04)",
                        },
                      }}
                    >
                      <Image size={18} />
                      <Typography>Select Logo</Typography>
                    </Box>
                    {watermark && (
                      <Typography
                        variant="body2"
                        sx={{ mt: 1, color: "text.secondary" }}
                      >
                        Selected: {watermark.name}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              {/* Generated meme controls */}
              <Box sx={{ mt: 4, mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                  Your Ad Idea
                </Typography>
                <TextareaAutosize
                  value={memeText}
                  onChange={(e) => handleMemeTextChange(e.target.value)}
                  placeholder="(Optional) Enter your Facebook Ad description/idea..."
                  style={{
                    width: "100%",
                    padding: "12px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    fontFamily: "inherit",
                    fontSize: "16px",
                    minHeight: "40px",
                    maxHeight: "120px",
                    resize: "none",
                    backgroundColor: "#fff",
                  }}
                />
              </Box>
              <FacebookAdUploadEditor {...editorProps} />
            </>
          )}

          <Box
            sx={{
              mt: "auto",
              display: "flex",
              justifyContent: "center",
              pb: 2,
            }}
          >
            <Tooltip
              title={`${imageGenerationType === "upload" ? "3" : "10"} Credits`}
              arrow
            >
              <Button
                variant="contained"
                onClick={generatedMeme ? handleRegenerate : handleGenerate}
                disabled={
                  isLoading ||
                  isRegenerating ||
                  (!selectedFile && imageGenerationType === "upload")
                }
                startIcon={
                  isLoading || isRegenerating ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Wand2 />
                  )
                }
                sx={{
                  mt: generatedMeme ? 2 : 0,
                  py: 1.5,
                  px: 4,
                  bgcolor: "#9c27b0",
                  "&:hover": { bgcolor: "#7b1fa2" },
                  textTransform: "none",
                }}
              >
                {isLoading
                  ? "Generating..."
                  : isRegenerating
                  ? "Regenerating..."
                  : generatedMeme
                  ? "Regenerate Ad"
                  : "Create Ad"}
              </Button>
            </Tooltip>
          </Box>
        </Box>

        {/* Right Panel */}
        <Box
          sx={{
            bgcolor: "#ffffff",
            borderRadius: 3,
            p: { xs: 2, md: 4 },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 0,
            height: "auto",
            width: "100%",
            order: { xs: 1, md: 2 },
            boxShadow: "none",
            border: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          {previewUrl ? (
            <FacebookAdUploadCanvas {...canvasProps} />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <ImagePlus
                size={48}
                style={{
                  color: "#666666",
                  marginBottom: "16px",
                  opacity: 0.7,
                }}
              />
              <Typography
                color="text.secondary"
                variant="h6"
                sx={{
                  fontSize: { xs: "1rem", md: "1.25rem" },
                }}
              >
                Upload an image or generate an AI one
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <NavigationBlockerDialog />
    </Container>
  );
};

export default FacebookAdUpload;
