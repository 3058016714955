import React from "react";
import { Box, Typography, Slider, Tabs, Tab, TextField, Select, MenuItem, Switch } from "@mui/material";

const EditorTabs = {
  TEXT: "TEXT",
  INSET: "INSET",
};

const TrafficImageEditor = ({
  text,
  onTextChange,
  fontSize,
  onFontSizeChange,
  strokeWidth,
  onStrokeWidthChange,
  selectedTab,
  onTabChange,
  insetPosition,
  onInsetPositionChange,
  insetSize,
  onInsetSizeChange,
  selectedFont,
  onFontChange,
  fontOptions,
  useInsetImage,
  onUseInsetImageChange,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={selectedTab}
        onChange={(e, newValue) => onTabChange(newValue)}
        sx={{ mb: 3 }}
      >
        <Tab
          label="Text"
          value={EditorTabs.TEXT}
          title="Edit text properties"
        />
        <Tab
          label="Inset Image"
          value={EditorTabs.INSET}
          title="Adjust inset image position"
        />
      </Tabs>

      {selectedTab === EditorTabs.TEXT && (
        <Box>
          <Typography gutterBottom>Text</Typography>
          <TextField
            value={text}
            onChange={(e) => onTextChange(e.target.value)}
            placeholder="Enter text..."
            multiline
            rows={3}
            fullWidth
            sx={{ mb: 2 }}
          />

          <Typography gutterBottom>Font</Typography>
          <Select
            value={selectedFont}
            onChange={(e) => onFontChange(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          >
            {fontOptions.map((font) => (
              <MenuItem
                key={font.value}
                value={font.value}
                sx={{ fontFamily: font.value }}
              >
                {font.label}
              </MenuItem>
            ))}
          </Select>

          <Typography gutterBottom>Font Size</Typography>
          <Slider
            value={fontSize}
            onChange={(_, value) => onFontSizeChange(value)}
            min={48}
            max={96}
            step={1}
            sx={{ mb: 2 }}
          />

          <Typography gutterBottom>Outline Width</Typography>
          <Slider
            value={strokeWidth}
            onChange={(_, value) => onStrokeWidthChange(value)}
            min={0}
            max={20}
            step={1}
          />
        </Box>
      )}

      {selectedTab === EditorTabs.INSET && (
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
            <Typography>Show Inset Image</Typography>
            <Switch
              checked={useInsetImage}
              onChange={(e) => onUseInsetImageChange(e.target.checked)}
              size="small"
            />
          </Box>

          {useInsetImage && (
            <>
              <Typography gutterBottom>Size (%)</Typography>
              <Slider
                value={insetSize}
                onChange={(_, value) => onInsetSizeChange(value)}
                min={10}
                max={30}
                step={1}
                sx={{ mb: 2 }}
              />

              <Typography gutterBottom>Horizontal Position (%)</Typography>
              <Slider
                value={insetPosition.x}
                onChange={(_, value) =>
                  onInsetPositionChange({ ...insetPosition, x: value })
                }
                min={10}
                max={90}
                step={5}
              />
              <Typography gutterBottom>Vertical Position (%)</Typography>
              <Slider
                value={insetPosition.y}
                onChange={(_, value) =>
                  onInsetPositionChange({ ...insetPosition, y: value })
                }
                min={10}
                max={90}
                step={5}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TrafficImageEditor;
