import React, { forwardRef, useEffect, useRef, useState, useMemo } from "react";
import { Box } from "@mui/material";

const EMOJI_OPTIONS = [
  "👇", // pointing down
  "👍", // thumbs up
  // '🔥',
  // '👊',
];

const FacebookAdUploadCanvas = forwardRef((props, ref) => {
  const {
    preview,
    memeText,
    bottomText,
    textPosition,
    bottomTextPosition,
    fontSize,
    bottomFontSize,
    textAreaWidth,
    bottomTextAreaWidth,
    strokeWidth,
    bottomStrokeWidth,
    textFillColor,
    bottomTextFillColor,
    textStrokeColor,
    bottomTextStrokeColor,
    selectedFont,
    bottomFont,
    onTextPositionChange,
    onBottomTextPositionChange,
    backgroundColor = "#FFFFFF",
    generatedMeme,
    selectedTab,
    emojiPosition,
    onEmojiPositionChange,
    emojiSize,
    customEmoji,
    isEmojiDragging,
    onEmojiDragStart,
    onEmojiDragEnd,
    topTextHeight,
    bottomTextHeight,
    onImagePositionChange,
    onImageScaleChange,
    imagePosition,
    imageScale,
  } = props;

  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [isImageDragging, setIsImageDragging] = useState(false);
  const [imageDragStart, setImageDragStart] = useState({ x: 0, y: 0 });

  const CANVAS_SIZE = 600;
  const TOP_HEIGHT_PERCENTAGE = 0.18;
  const BOTTOM_HEIGHT_PERCENTAGE = 0.12;
  const TOP_HEIGHT = CANVAS_SIZE * TOP_HEIGHT_PERCENTAGE;
  const BOTTOM_HEIGHT = CANVAS_SIZE * BOTTOM_HEIGHT_PERCENTAGE;
  const IMAGE_AREA_HEIGHT = CANVAS_SIZE - (TOP_HEIGHT + BOTTOM_HEIGHT);

  const selectedEmoji = useMemo(() => {
    return EMOJI_OPTIONS[Math.floor(Math.random() * EMOJI_OPTIONS.length)];
  }, []);

  useEffect(() => {
    if (preview && !imageRef.current) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = preview;
      img.onload = () => {
        imageRef.current = img;
        drawCanvas();
      };
    } else if (imageRef.current) {
      if (imageRef.current.src !== preview) {
        imageRef.current = null;
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = preview;
        img.onload = () => {
          imageRef.current = img;
          drawCanvas();
        };
      } else {
        drawCanvas();
      }
    }
  }, [
    preview,
    memeText,
    textPosition,
    fontSize,
    textAreaWidth,
    strokeWidth,
    textFillColor,
    textStrokeColor,
    selectedFont,
    backgroundColor,
    imagePosition,
    imageScale,
    topTextHeight,
    bottomTextHeight,
  ]);

  useEffect(() => {
    if (canvasRef.current && !textPosition.x && !textPosition.y) {
      onTextPositionChange({
        x: CANVAS_SIZE / 2,
        y: TOP_HEIGHT / 2,
      });
    }
    if (canvasRef.current && !bottomTextPosition.x && !bottomTextPosition.y) {
      onBottomTextPositionChange({
        x: CANVAS_SIZE / 2,
        y: CANVAS_SIZE - BOTTOM_HEIGHT / 2,
      });
    }
  }, [canvasRef.current]);

  useEffect(() => {
    if (canvasRef.current) {
      drawCanvas();
    }
  }, [
    selectedTab,
    memeText,
    bottomText,
    textPosition,
    bottomTextPosition,
    fontSize,
    bottomFontSize,
    strokeWidth,
    bottomStrokeWidth,
    textFillColor,
    bottomTextFillColor,
    textStrokeColor,
    bottomTextStrokeColor,
    selectedFont,
    bottomFont,
    backgroundColor,
    emojiPosition,
    emojiSize,
    customEmoji,
    imagePosition,
    imageScale,
  ]);

  const drawCanvas = (forDownload = false) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = imageRef.current;

    if (!img) return;

    canvas.width = CANVAS_SIZE;
    canvas.height = CANVAS_SIZE;

    // Draw background
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);

    // Calculate available height for image
    const availableHeight = CANVAS_SIZE - TOP_HEIGHT - BOTTOM_HEIGHT;

    // Calculate dimensions maintaining aspect ratio with fixed height
    const aspectRatio = img.width / img.height;
    const defaultHeight = availableHeight;
    const defaultWidth = defaultHeight * aspectRatio;

    // Center horizontally
    const defaultX = (CANVAS_SIZE - defaultWidth) / 2;
    const defaultY = TOP_HEIGHT; // Start right after top area

    // Draw image
    ctx.save();
    ctx.beginPath();
    ctx.rect(0, TOP_HEIGHT, CANVAS_SIZE, IMAGE_AREA_HEIGHT);
    ctx.clip();

    if (!generatedMeme) {
      // Before API call: draw at center position
      ctx.drawImage(img, defaultX, defaultY, defaultWidth, defaultHeight);
    } else {
      // After API call: apply user adjustments from center position
      const currentScale = imageScale || 1;
      const currentWidth = defaultWidth * currentScale;
      const currentHeight = defaultHeight * currentScale;

      // Convert percentage to pixels, where 0% is center
      const xOffset =
        (imagePosition ? imagePosition.x : 0) * (CANVAS_SIZE / 100);
      const yOffset =
        (imagePosition ? imagePosition.y : 0) * (CANVAS_SIZE / 100);

      // Apply offsets to default centered position
      const finalX = defaultX + xOffset;
      const finalY = defaultY + yOffset;

      ctx.drawImage(img, finalX, finalY, currentWidth, currentHeight);
    }

    ctx.restore();

    // Draw texts and emoji
    if (memeText && textPosition) {
      drawTopText(ctx);
    }

    if (bottomText && bottomTextPosition && generatedMeme) {
      drawBottomText(ctx);
    }

    if (generatedMeme) {
      drawEmoji(ctx);
    }

    // Only draw selection outline if not downloading and a tab is selected
    if (!forDownload && selectedTab !== null) {
      drawSelectionOutline(ctx);
    }
  };

  const drawSelectionOutline = (ctx) => {
    ctx.save();
    ctx.strokeStyle = "#9c27b0";
    ctx.lineWidth = 2;
    ctx.setLineDash([5, 5]);

    switch (selectedTab) {
      case "TOP_TEXT":
        ctx.strokeRect(0, 0, CANVAS_SIZE, TOP_HEIGHT);
        break;
      case "BOTTOM_TEXT":
        ctx.strokeRect(
          0,
          CANVAS_SIZE - BOTTOM_HEIGHT,
          CANVAS_SIZE,
          BOTTOM_HEIGHT
        );
        break;
      case "IMAGE":
        ctx.strokeRect(0, TOP_HEIGHT, CANVAS_SIZE, IMAGE_AREA_HEIGHT);
        break;
      case "BACKGROUND":
        ctx.strokeRect(0, 0, CANVAS_SIZE, TOP_HEIGHT);
        ctx.strokeRect(
          0,
          CANVAS_SIZE - BOTTOM_HEIGHT,
          CANVAS_SIZE,
          BOTTOM_HEIGHT
        );
        break;
      case "EMOJI":
        if (emojiPosition) {
          const size = emojiSize || 130;
          const halfSize = size / 2;
          ctx.strokeRect(
            emojiPosition.x - halfSize,
            emojiPosition.y - halfSize,
            size,
            size
          );
        }
        break;
    }
    ctx.restore();
  };

  const wrapText = (ctx, text, maxWidth) => {
    const words = text.split(" ");
    const lines = [];
    let currentLine = words[0];
    const MAX_CHARS_PER_LINE = 40;

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const testLine = `${currentLine} ${word}`;

      if (
        ctx.measureText(testLine).width <= maxWidth &&
        currentLine.length + word.length + 1 <= MAX_CHARS_PER_LINE
      ) {
        currentLine = testLine;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  };

  const drawTopText = (ctx) => {
    if (!memeText) return;

    ctx.save();

    const textX = CANVAS_SIZE / 2; // Center horizontally
    const textY = (CANVAS_SIZE * (topTextHeight / 100)) / 2; // Center in top area

    const padding = { x: 20, y: 10 };
    const availableWidth = CANVAS_SIZE - padding.x * 2;
    const availableHeight = CANVAS_SIZE * (topTextHeight / 100) - padding.y * 2;

    let fontSize = availableHeight;
    let fits = false;
    let lines = [];

    while (!fits && fontSize > 1) {
      ctx.font = `bold ${fontSize}px ${selectedFont}`;
      lines = wrapText(ctx, memeText, availableWidth);
      const lineHeight = fontSize * 1.2;
      const totalHeight = lines.length * lineHeight;

      if (totalHeight <= availableHeight) {
        fits = true;
      } else {
        fontSize--;
      }
    }

    ctx.font = `bold ${fontSize}px ${selectedFont}`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    if (strokeWidth > 0) {
      ctx.strokeStyle = textStrokeColor;
      ctx.lineWidth = strokeWidth;
      ctx.lineJoin = "round";
    }

    ctx.fillStyle = textFillColor;

    const lineHeight = fontSize * 1.2;
    const totalTextHeight = lines.length * lineHeight;

    let currentY = textY - totalTextHeight / 2 + lineHeight / 2;

    lines.forEach((line) => {
      if (strokeWidth > 0) {
        ctx.strokeText(line, textX, currentY);
      }
      ctx.fillText(line, textX, currentY);
      currentY += lineHeight;
    });

    ctx.restore();
  };

  const drawBottomText = (ctx) => {
    if (!generatedMeme || !bottomText) return;

    ctx.save();

    const textX = CANVAS_SIZE / 2; // Center horizontally
    const bottomAreaStart =
      CANVAS_SIZE - CANVAS_SIZE * (bottomTextHeight / 100);
    const textY =
      bottomAreaStart + (CANVAS_SIZE * (bottomTextHeight / 100)) / 2; // Center in bottom area

    const padding = { x: 20, y: 10 };
    const availableWidth = CANVAS_SIZE - padding.x * 2;
    const availableHeight =
      CANVAS_SIZE * (bottomTextHeight / 100) - padding.y * 2;

    let fontSize = availableHeight;
    let fits = false;

    while (!fits && fontSize > 1) {
      ctx.font = `bold ${fontSize}px ${bottomFont}`;
      const metrics = ctx.measureText(bottomText);

      if (metrics.width <= availableWidth && fontSize <= availableHeight) {
        fits = true;
      } else {
        fontSize--;
      }
    }

    ctx.font = `bold ${fontSize}px ${bottomFont}`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    if (bottomStrokeWidth > 0) {
      ctx.strokeStyle = bottomTextStrokeColor;
      ctx.lineWidth = bottomStrokeWidth;
      ctx.lineJoin = "round";
    }

    ctx.fillStyle = bottomTextFillColor;

    if (bottomStrokeWidth > 0) {
      ctx.strokeText(bottomText, textX, textY);
    }
    ctx.fillText(bottomText, textX, textY);

    ctx.restore();
  };

  const drawEmoji = (ctx) => {
    if (!generatedMeme || !emojiPosition) return;

    const size = emojiSize || 130;

    ctx.save();
    ctx.font = `${size}px Arial`;
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";

    // Ensure emoji stays within bounds
    const x = Math.min(
      Math.max(size / 2, emojiPosition.x),
      CANVAS_SIZE - size / 2
    );
    const y = Math.min(
      Math.max(size / 2, emojiPosition.y),
      CANVAS_SIZE - size / 2
    );

    ctx.fillText(customEmoji || selectedEmoji, x, y);
    ctx.restore();
  };

  const handleEmojiDrag = (e) => {
    if (selectedTab !== "EMOJI" || !isEmojiDragging) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    // Get raw position
    let x = Math.max(
      emojiSize / 2,
      Math.min(CANVAS_SIZE - emojiSize / 2, e.clientX - rect.left)
    );
    let y = Math.max(
      emojiSize / 2,
      Math.min(CANVAS_SIZE - emojiSize / 2, e.clientY - rect.top)
    );

    // Round to nearest 5px
    x = Math.round(x / 5) * 5;
    y = Math.round(y / 5) * 5;

    onEmojiPositionChange({ x, y });
  };

  const handleMouseDown = (e) => {
    if (selectedTab === "EMOJI") {
      onEmojiDragStart();
    } else {
      handleImageMouseDown(e);
    }
  };

  const handleMouseUp = () => {
    if (selectedTab === "EMOJI") {
      onEmojiDragEnd();
    } else {
      handleImageMouseUp();
    }
  };

  const drawCanvasForDownload = () => {
    drawCanvas(true); // Pass true to indicate this is for download
  };

  const handleImageMouseDown = (e) => {
    if (selectedTab !== "IMAGE" || !generatedMeme) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    setIsImageDragging(true);
    setImageDragStart({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const handleImageMouseMove = (e) => {
    if (!isImageDragging || !imageRef.current || !generatedMeme) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Convert to percentage (-100 to 100) where 100% = 600px movement
    const xPercent = ((x - CANVAS_SIZE / 2) / CANVAS_SIZE) * 100;
    const yPercent =
      ((y - (TOP_HEIGHT + IMAGE_AREA_HEIGHT / 2)) / CANVAS_SIZE) * 100;

    onImagePositionChange({
      x: xPercent,
      y: yPercent,
    });
  };

  const handleImageMouseUp = () => {
    setIsImageDragging(false);
  };

  const handleWheel = (e) => {
    if (!generatedMeme || selectedTab !== "IMAGE") return;

    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      const scaleFactor = e.deltaY > 0 ? 0.95 : 1.05;
      const newScale = Math.max(0.5, Math.min(imageScale * scaleFactor, 2));
      onImageScaleChange(newScale);
    }
  };

  React.useImperativeHandle(ref, () => ({
    drawCanvasForDownload,
    getCanvas: () => canvasRef.current,
  }));

  return (
    <Box
      sx={{
        width: `${CANVAS_SIZE}px`,
        height: `${CANVAS_SIZE}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
      }}
    >
      <canvas
        ref={(el) => {
          canvasRef.current = el;
          if (typeof ref === "function") ref(el);
          else if (ref) ref.current = el;
        }}
        style={{
          width: `${CANVAS_SIZE}px`,
          height: `${CANVAS_SIZE}px`,
          cursor: isImageDragging ? "grabbing" : "grab",
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={(e) => {
          if (selectedTab === "EMOJI") {
            handleEmojiDrag(e);
          } else {
            handleImageMouseMove(e);
          }
        }}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onWheel={handleWheel}
      />
    </Box>
  );
});

FacebookAdUploadCanvas.displayName = "FacebookAdUploadCanvas";

export default FacebookAdUploadCanvas;
