import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Container,
  Grid,
} from "@mui/material";
import { Lightbulb, Edit } from "lucide-react";
import { useNavigate } from "react-router-dom";

const GetIdeasCard = () => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        minHeight: 280,
      }}
    >
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: "32px",
          height: "100%",
        }}
      >
        <Lightbulb
          size={24}
          style={{ color: "#9c27b0", marginBottom: "16px" }}
        />
        <Typography variant="h6" gutterBottom>
          Get Ideas
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mb: 3, flexGrow: 1 }}
        >
          Generate social-first blog post ideas and then write the blog posts.
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/blog-post-ideas")}
          fullWidth
          sx={{
            bgcolor: "#9c27b0",
            "&:hover": { bgcolor: "#7b1fa2" },
            textTransform: "none",
            padding: "12px 0",
            fontSize: "1rem",
          }}
        >
          Get Ideas
        </Button>
      </CardContent>
    </Card>
  );
};

const WritePostCard = () => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        minHeight: 280,
      }}
    >
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: "32px",
          height: "100%",
        }}
      >
        <Edit size={24} style={{ color: "#9c27b0", marginBottom: "16px" }} />
        <Typography variant="h6" gutterBottom>
          Write on Topic
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mb: 3, flexGrow: 1 }}
        >
          Use this option if you already have an idea what you want to write
          about.
        </Typography>
        <Button
          variant="contained"
          fullWidth
          onClick={() => navigate("/write-post")}
          sx={{
            bgcolor: "#9c27b0",
            "&:hover": { bgcolor: "#7b1fa2" },
            textTransform: "none",
            padding: "12px 0",
            fontSize: "1rem",
          }}
        >
          Write Post
        </Button>
      </CardContent>
    </Card>
  );
};

const SocialFirst = () => {
  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Typography
        variant="h5"
        component="h1"
        sx={{
          mb: 4,
          fontWeight: "bold",
        }}
      >
        Social-First Blog Posts
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          spacing={4}
          sx={{
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          <Grid item xs={12} md={6}>
            <Box sx={{ maxWidth: "550px", margin: "0 auto" }}>
              <GetIdeasCard />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ maxWidth: "550px", margin: "0 auto" }}>
              <WritePostCard />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SocialFirst;
