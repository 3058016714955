import React, { forwardRef, useEffect, useRef } from "react";
import { Box } from "@mui/material";

const CANVAS_SIZE = 1024;
const CANVAS_HEIGHT = 1024;

const TrafficImageCanvas = forwardRef(
  (
    {
      mainImage,
      insetImage,
      useInsetImage,
      text,
      textPosition,
      fontSize,
      strokeWidth = 5,
      insetPosition,
      insetSize = 20,
      isImageDragging,
      onMouseDown,
      onMouseMove,
      onMouseUp,
      onMouseLeave,
      selectedFont,
    },
    forwardedRef
  ) => {
    const canvasRef = useRef(null);
    const offscreenCanvasRef = useRef(null);

    useEffect(() => {
      if (forwardedRef) {
        forwardedRef.current = {
          getCanvas: () => canvasRef.current,
        };
      }
    }, [forwardedRef]);

    useEffect(() => {
      if (!offscreenCanvasRef.current) {
        offscreenCanvasRef.current = document.createElement("canvas");
      }
    }, []);

    useEffect(() => {
      const canvas = canvasRef.current;
      if (!canvas || !mainImage || (useInsetImage && !insetImage)) return;

      const offscreenCanvas = offscreenCanvasRef.current;
      offscreenCanvas.width = CANVAS_SIZE;
      offscreenCanvas.height = CANVAS_HEIGHT;
      canvas.width = CANVAS_SIZE;
      canvas.height = CANVAS_HEIGHT;

      const ctx = offscreenCanvas.getContext("2d");
      ctx.clearRect(0, 0, CANVAS_SIZE, CANVAS_HEIGHT);

      // Draw main background
      const mainImg = new Image();
      mainImg.onload = () => {
        ctx.drawImage(mainImg, 0, 0, CANVAS_SIZE, CANVAS_HEIGHT);

        if (useInsetImage) {
          // Draw circular inset
          const insetImg = new Image();
          insetImg.onload = () => {
            const actualInsetSize = CANVAS_SIZE * (insetSize / 100);
            const x = (CANVAS_SIZE * insetPosition.x) / 100;
            const y = (CANVAS_HEIGHT * insetPosition.y) / 100;

            // Draw white circle background with border
            ctx.save();
            ctx.beginPath();
            ctx.arc(x, y, actualInsetSize / 2 + 10, 0, Math.PI * 2);
            ctx.fillStyle = "white";
            ctx.fill();
            ctx.strokeStyle = "rgba(0, 0, 0, 0.1)";
            ctx.lineWidth = 2;
            ctx.stroke();
            ctx.restore();

            // Draw the inset image
            ctx.save();
            ctx.beginPath();
            ctx.arc(x, y, actualInsetSize / 2, 0, Math.PI * 2);
            ctx.closePath();
            ctx.clip();
            ctx.drawImage(
              insetImg,
              x - actualInsetSize / 2,
              y - actualInsetSize / 2,
              actualInsetSize,
              actualInsetSize
            );
            ctx.restore();

            drawText();
          };
          insetImg.src = insetImage;
        } else {
          drawText();
        }

        function drawText() {
          // Draw text with improved styling
          const fontSizeToUse = fontSize || 72;
          const fontToUse = selectedFont || '"Arial Black", Impact, sans-serif';
          ctx.font = `bold ${fontSizeToUse}px ${fontToUse}`;
          ctx.textAlign = "left";
          ctx.textBaseline = "bottom";

          // Add multiple shadows for better visibility
          ctx.shadowColor = "rgba(0, 0, 0, 0.6)";
          ctx.shadowBlur = 15;
          ctx.shadowOffsetX = 3;
          ctx.shadowOffsetY = 3;

          // Draw text stroke
          ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
          ctx.lineWidth = strokeWidth;
          ctx.lineJoin = "round";

          // Draw text wrapped
          const words = text.toUpperCase().split(" ");
          let line = "";
          let yy = textPosition.y;
          const maxWidth = CANVAS_SIZE * 0.8;
          const lineHeight = fontSizeToUse * 1.2;
          const padding = fontSizeToUse;

          // Calculate total height of text to adjust starting position
          const lines = [];
          words.forEach((word) => {
            const testLine = line + word + " ";
            const metrics = ctx.measureText(testLine);
            if (metrics.width > maxWidth && line) {
              lines.push(line);
              line = word + " ";
            } else {
              line = testLine;
            }
          });
          if (line) {
            lines.push(line);
          }

          // Adjust starting Y position to ensure text fits above bottom
          const totalHeight = lines.length * lineHeight;
          const bottomPadding = 0;
          yy = CANVAS_HEIGHT - bottomPadding - totalHeight;

          // Calculate left margin for text (10% from left edge)
          const leftMargin = CANVAS_SIZE * 0.1;

          // Draw the lines
          lines.forEach((line) => {
            ctx.strokeText(line.trim(), leftMargin, yy);
            ctx.fillStyle = "white";
            ctx.fillText(line.trim(), leftMargin, yy);
            yy += lineHeight;
          });

          // Copy to visible canvas
          const visibleCtx = canvas.getContext("2d");
          visibleCtx.clearRect(0, 0, CANVAS_SIZE, CANVAS_HEIGHT);
          visibleCtx.drawImage(offscreenCanvas, 0, 0);
        }
      };
      mainImg.src = mainImage;
    }, [
      mainImage,
      insetImage,
      useInsetImage,
      text,
      textPosition,
      fontSize,
      strokeWidth,
      insetPosition,
      insetSize,
      selectedFont,
    ]);

    return (
      <Box sx={{ width: "100%", position: "relative" }}>
        <canvas
          ref={canvasRef}
          style={{
            width: "100%",
            height: "auto",
            aspectRatio: `${CANVAS_SIZE}/${CANVAS_HEIGHT}`,
            cursor: isImageDragging ? "grabbing" : "grab",
          }}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseLeave}
        />
      </Box>
    );
  }
);

TrafficImageCanvas.displayName = "TrafficImageCanvas";

export default TrafficImageCanvas;
