import React, { useState, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  TextareaAutosize,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  Upload as AntUpload,
  Wand2,
  Download,
  PlusCircle,
  ImagePlus,
  Image,
} from "lucide-react";
import generalApi from "../../../api/generalApi";
import { toast } from "react-toastify";
import LanguageSelector, { languages } from "../../common/LanguageSelector";
import MemeUploadEditor from "./MemeUploadEditor";
import MemeUploadCanvas from "./MemeUploadCanvas";
import ProjectSelector from "../../Project/ProjectSelector";
import { useLimits } from "../../../context/LimitsContext";

// Define fontOptions outside the component
const fontOptions = [
  { value: '"Arial Black", Impact, sans-serif', label: "Impact (Default)" },
  { value: "Arial, sans-serif", label: "Arial" },
  { value: "Comic Sans MS, cursive", label: "Comic Sans" },
  { value: "Times New Roman, serif", label: "Times New Roman" },
  { value: "Courier New, monospace", label: "Courier" },
  { value: "Georgia, serif", label: "Georgia" },
  { value: "Verdana, sans-serif", label: "Verdana" },
  { value: "Helvetica, Arial, sans-serif", label: "Helvetica" },
];

const MemeUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [memeText, setMemeText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState(() => {
    // Initialize with value from localStorage or default to 'en-US'
    return localStorage.getItem("selectedLanguage") || "en-US";
  });
  const [generatedMeme, setGeneratedMeme] = useState(null);
  const [fontSize, setFontSize] = useState(50);
  const [textAreaWidth, setTextAreaWidth] = useState(100);
  const [strokeWidth, setStrokeWidth] = useState(0);
  const [textFillColor, setTextFillColor] = useState("#FFFFFF");
  const [textStrokeColor, setTextStrokeColor] = useState("#000000");
  const [selectedFont, setSelectedFont] = useState(
    '"Arial Black", Impact, sans-serif'
  );
  const [textPosition, setTextPosition] = useState({ x: 0, y: 0 });
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [memeDescription, setMemeDescription] = useState("");
  const [projectDialogOpen, setProjectDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { limits, fetchLimits } = useLimits();
  const [textboxCount, setTextboxCount] = useState(1);
  const [secondaryTextPosition, setSecondaryTextPosition] = useState({
    x: 0,
    y: 0,
  });
  const [secondaryText, setSecondaryText] = useState("");
  const [watermark, setWatermark] = useState(null);

  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    handleFileSelection(file);
  };

  const handleFileSelection = (file) => {
    if (file) {
      // Clear previous file and preview
      setSelectedFile(null);
      setPreviewUrl(null);

      // Validate file
      if (!file.type.match(/image\/(jpeg|png|gif)/i)) {
        setError("Please upload a valid image file (JPEG, PNG, or GIF)");
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        setError("File size should be less than 2MB");
        return;
      }

      // Create new preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setSelectedFile(file);
        setError(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const getLanguageName = (code) => {
    const language = languages.find((lang) => lang.code === code);
    return language ? language.name : "English";
  };

  const handleGenerate = async () => {
    if (!selectedFile || !memeText.trim()) {
      setError("Please provide both an image and text");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const base64Image = await convertToBase64(selectedFile);
      let requestData = {
        imageBase64: base64Image,
        prompt: memeText.trim(),
        language: getLanguageName(language),
        textboxCount,
      };

      if (watermark) {
        const watermarkBase64 = await convertToBase64(watermark);
        requestData.watermark = watermarkBase64;
      }

      const response = await generalApi.memes.generateMeme(requestData);
      setGeneratedMeme(response);
      if (response.imageBase64) {
        setPreviewUrl(response.imageBase64);
      }

      if (canvasRef.current) {
        const canvas = canvasRef.current;
        if (textboxCount === 2) {
          // Set positions for both text boxes
          setTextPosition({
            x: canvas.width / 2,
            y: canvas.height * 0.2, // Top 20%
          });
          setSecondaryTextPosition({
            x: canvas.width / 2,
            y: canvas.height * 0.8, // Bottom 80%
          });
        } else {
          setTextPosition({
            x: canvas.width / 2,
            y: canvas.height / 2,
          });
        }
      }

      await fetchLimits();
      toast.success("Meme generated successfully!");
    } catch (err) {
      console.error("Generation error:", err);
      setError(err.message || "Failed to generate meme");
      toast.error(err.message || "Failed to generate meme");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const link = document.createElement("a");
    link.download = "meme.png";
    link.href = canvas.toDataURL("image/png");
    link.click();
  };

  const handleNewMeme = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setMemeText("");
    setMemeDescription("");
    setGeneratedMeme(null);
    setError(null);
  };

  const handleCreateVersion = async () => {
    setIsGenerating(true);
    try {
      const base64Image = await convertToBase64(selectedFile);

      let requestData = {
        imageBase64: base64Image,
        prompt: memeText.trim(),
        language: getLanguageName(language),
        textboxCount,
      };

      if (watermark) {
        const watermarkBase64 = await convertToBase64(watermark);
        requestData.watermark = watermarkBase64;
      }

      const response = await generalApi.memes.generateMeme(requestData);
      setGeneratedMeme(response);

      if (response.imageBase64) {
        setPreviewUrl(response.imageBase64);
      }

      await fetchLimits();

      toast.success("New version generated!");
    } catch (err) {
      console.error("Generation error:", err);
      toast.error(err.message || "Failed to generate new version");
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    handleFileSelection(file);
  }, []);

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    handleFileSelection(file);
    // Reset the input value to allow selecting the same file again
    e.target.value = "";
  };

  const handleAddToProject = () => {
    setProjectDialogOpen(true);
  };

  const handleProjectSelect = async (project) => {
    if (!project || !canvasRef.current) {
      return;
    }

    try {
      setIsUploading(true);

      // Convert canvas to blob
      const canvas = canvasRef.current;
      const blob = await new Promise((resolve) =>
        canvas.getCanvas().toBlob(resolve, "image/png")
      );

      // Create a File object from the blob
      const file = new File([blob], "meme.png", { type: "image/png" });

      // Call the upload API
      await generalApi.images.uploadImage(file, project._id, "meme", memeText);

      toast.success(`Added to project: ${project.name}`);
      setProjectDialogOpen(false); // Close dialog only after successful upload
    } catch (error) {
      console.error("Error uploading to project:", error);
      toast.error(error.message || "Failed to add meme to project");
    } finally {
      setIsUploading(false);
    }
  };

  // Create the editor props object
  const editorProps = {
    from: "memeupload",
    memeText:
      textboxCount === 2
        ? `${generatedMeme?.memeText?.topText}\n\n${generatedMeme?.memeText?.bottomText}`
        : generatedMeme?.memeText?.bestUniqueTake || "",
    memeDescription,
    fontSize,
    textAreaWidth,
    strokeWidth,
    textFillColor,
    textStrokeColor,
    selectedFont,
    fontOptions,
    onMemeTextChange: (text) =>
      setGeneratedMeme((prev) => ({
        ...prev,
        memeText: { ...prev.memeText, bestUniqueTake: text },
      })),
    onMemeDescriptionChange: (text) => setMemeDescription(text),
    onFontSizeChange: (size) => setFontSize(size),
    onTextAreaWidthChange: (width) => setTextAreaWidth(width),
    onStrokeWidthChange: (width) => setStrokeWidth(width),
    onTextFillColorChange: (color) => setTextFillColor(color),
    onTextStrokeColorChange: (color) => setTextStrokeColor(color),
    onFontChange: (font) => setSelectedFont(font),
  };

  // Create the canvas props object
  const canvasProps = {
    ref: canvasRef,
    imageRef: imageRef,
    preview: previewUrl,
    memeText:
      textboxCount === 2
        ? generatedMeme?.memeText?.topText
        : generatedMeme?.memeText?.bestUniqueTake || "",
    textPosition,
    fontSize,
    textAreaWidth,
    strokeWidth,
    textFillColor,
    textStrokeColor,
    selectedFont,
    onTextPositionChange: (newPosition) => setTextPosition(newPosition),
    textboxCount,
    secondaryText: generatedMeme?.memeText?.bottomText || "",
    secondaryTextPosition,
    onSecondaryTextPositionChange: (newPosition) =>
      setSecondaryTextPosition(newPosition),
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "calc(100vh - 84px)",
        py: 2,
        px: { xs: 2, sm: 4, md: 6 },
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header with title and action buttons */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          gap: { xs: 2, sm: 0 },
          mb: 4,
          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontWeight: "bold",
            mt: 2,
            fontSize: { xs: "1.5rem", sm: "1.5rem" },
          }}
        >
          Create Your Meme
        </Typography>

        {generatedMeme && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              width: { xs: "100%", sm: "auto" },
              flexWrap: "wrap",
            }}
          >
            <Tooltip title="1 Credit" arrow>
              <Button
                size="small"
                variant="contained"
                onClick={handleCreateVersion}
                startIcon={
                  isGenerating ? (
                    <CircularProgress size={18} />
                  ) : (
                    <Wand2 size={18} />
                  )
                }
                disabled={isGenerating}
                sx={{
                  bgcolor: "#9c27b0",
                  "&:hover": { bgcolor: "#7b1fa2" },
                  textTransform: "none",
                }}
              >
                Regenerate
              </Button>
            </Tooltip>

            <Button
              size="small"
              variant="contained"
              onClick={handleDownload}
              startIcon={<Download size={18} />}
              sx={{
                bgcolor: "#9c27b0",
                "&:hover": { bgcolor: "#7b1fa2" },
                textTransform: "none",
              }}
            >
              Download
            </Button>

            <Button
              size="small"
              variant="outlined"
              onClick={handleAddToProject}
              sx={{
                color: "#9c27b0",
                borderColor: "#9c27b0",
                bgcolor: "#fff",
                "&:hover": {
                  bgcolor: "rgba(156, 39, 176, 0.04)",
                  borderColor: "#7b1fa2",
                },
                textTransform: "none",
              }}
            >
              Add to Project
            </Button>

            <Button
              size="small"
              variant="outlined"
              onClick={handleNewMeme}
              startIcon={<PlusCircle size={18} />}
              sx={{
                color: "#9c27b0",
                borderColor: "#9c27b0",
                bgcolor: "#fff",
                "&:hover": {
                  bgcolor: "rgba(156, 39, 176, 0.04)",
                  borderColor: "#7b1fa2",
                },
                textTransform: "none",
              }}
            >
              New
            </Button>

            <ProjectSelector
              open={projectDialogOpen}
              onClose={() => setProjectDialogOpen(false)}
              onProjectSelect={handleProjectSelect}
              onAddProject={() => {
                setProjectDialogOpen(false);
                // Handle new project creation
              }}
              isUploading={isUploading}
            />
          </Box>
        )}
      </Box>

      {/* Main content area */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            md: "1fr 1fr",
          },
          gap: { xs: 2, md: 4 },
          flex: 1,
          width: "100%",
          minHeight: 0,
        }}
      >
        {/* Left Panel */}
        <Box
          sx={{
            bgcolor: "#ffffff",
            borderRadius: 3,
            p: { xs: 2, md: 4 },
            height: "auto",
            minHeight: 0,
            width: "100%",
            order: { xs: 2, md: 1 },
            boxShadow: "none",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!generatedMeme ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                minHeight: 0,
                overflow: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box sx={{ mb: 4, mt: 4 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, fontWeight: 500 }}
                  >
                    Choose Image
                  </Typography>
                  <Box
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    sx={{
                      border: "2px dashed",
                      borderColor: "divider",
                      borderRadius: 2,
                      p: 3,
                      textAlign: "center",
                      bgcolor: "#fff",
                      cursor: "pointer",
                      transition: "all 0.2s ease",
                      "&:hover": {
                        borderColor: "#9c27b0",
                        bgcolor: "rgba(156, 39, 176, 0.04)",
                      },
                    }}
                  >
                    <input
                      type="file"
                      hidden
                      accept="image/png,image/jpeg,image/gif"
                      onChange={handleInputChange}
                      id="file-upload"
                    />
                    <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
                      <AntUpload
                        size={32}
                        style={{
                          color: "#9c27b0",
                          marginBottom: "12px",
                          marginTop: "15px",
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{ mb: 1, color: "text.primary" }}
                      >
                        Drag and drop your image here
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        or click to browse
                      </Typography>
                    </label>
                  </Box>
                  {error && (
                    <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                      {error}
                    </Typography>
                  )}
                </Box>

                <Box sx={{ mb: 4 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, fontWeight: 500 }}
                  >
                    Your Meme Idea
                  </Typography>
                  <TextareaAutosize
                    value={memeText}
                    onChange={(e) => setMemeText(e.target.value)}
                    placeholder="Enter your meme idea..."
                    style={{
                      width: "100%",
                      padding: "12px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      minHeight: "40px",
                      maxHeight: "120px",
                      resize: "none",
                      backgroundColor: "#fff",
                    }}
                  />
                </Box>

                <Box sx={{ mb: 4 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, fontWeight: 500 }}
                  >
                    Language
                  </Typography>
                  <LanguageSelector
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    sx={{
                      bgcolor: "#fff",
                      "& .MuiSelect-select": {
                        bgcolor: "#fff",
                      },
                    }}
                  />
                </Box>

                <Box sx={{ mb: 4 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, fontWeight: 500 }}
                  >
                    Meme Captions
                  </Typography>
                  <RadioGroup
                    row
                    value={textboxCount}
                    onChange={(e) => setTextboxCount(Number(e.target.value))}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Single Caption"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Top & Bottom Captions"
                    />
                  </RadioGroup>
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, fontWeight: 500 }}
                  >
                    Logo Watermark (Optional)
                  </Typography>
                  <Box
                    component="label"
                    htmlFor="watermark-upload"
                    sx={{
                      border: "2px dashed",
                      borderColor: "divider",
                      borderRadius: 2,
                      p: 3,
                      textAlign: "center",
                      bgcolor: "#fff",
                      transition: "all 0.2s ease",
                      cursor: "pointer",
                      width: "100%",
                      minHeight: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      "&:hover": {
                        borderColor: "#9c27b0",
                        bgcolor: "rgba(156, 39, 176, 0.04)",
                      },
                    }}
                  >
                    <input
                      type="file"
                      hidden
                      accept="image/png,image/jpeg"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setWatermark(file);
                        }
                        e.target.value = "";
                      }}
                      id="watermark-upload"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mt: 2,
                        color: "#9c27b0",
                        borderRadius: 1,
                        pt: 2,
                        px: 2,
                        "&:hover": {
                          borderColor: "#7b1fa2",
                          bgcolor: "rgba(156, 39, 176, 0.04)",
                        },
                      }}
                    >
                      <Image size={18} />
                      <Typography>Select Logo</Typography>
                    </Box>
                    {watermark && (
                      <Typography
                        variant="body2"
                        sx={{ mt: 1, color: "text.secondary" }}
                      >
                        Selected: {watermark.name}
                      </Typography>
                    )}
                  </Box>
                </Box>

                {/* Generate Button */}
                <Box
                  sx={{
                    mt: "auto",
                    display: "flex",
                    justifyContent: "center",
                    pb: 2,
                  }}
                >
                  <Tooltip title="1 Credit" arrow>
                    <Button
                      variant="contained"
                      onClick={handleGenerate}
                      disabled={!selectedFile || !memeText.trim() || isLoading}
                      startIcon={
                        isLoading ? <CircularProgress size={20} /> : <Wand2 />
                      }
                      sx={{
                        py: 1.5,
                        px: 4,
                        bgcolor: "#9c27b0",
                        "&:hover": { bgcolor: "#7b1fa2" },
                        textTransform: "none",
                      }}
                    >
                      {isLoading ? "Generating..." : "Generate Meme"}
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              {/* Generated meme controls */}
              <Box sx={{ mt: 4, mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                  Your Meme Idea
                </Typography>
                <TextareaAutosize
                  value={memeText}
                  onChange={(e) => setMemeText(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "12px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    fontFamily: "inherit",
                    fontSize: "16px",
                    minHeight: "40px",
                    maxHeight: "120px",
                    resize: "none",
                    backgroundColor: "#fff",
                  }}
                />
              </Box>
              <MemeUploadEditor {...editorProps} />
            </>
          )}
        </Box>

        {/* Right Panel */}
        <Box
          sx={{
            bgcolor: "#ffffff",
            borderRadius: 3,
            p: { xs: 2, md: 4 },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 0,
            height: "auto",
            width: "100%",
            order: { xs: 1, md: 2 },
            boxShadow: "none",
            border: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          {previewUrl ? (
            <MemeUploadCanvas {...canvasProps} />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <ImagePlus
                size={48}
                style={{
                  color: "#666666",
                  marginBottom: "16px",
                  opacity: 0.7,
                }}
              />
              <Typography
                color="text.secondary"
                variant="h6"
                sx={{
                  fontSize: { xs: "1rem", md: "1.25rem" },
                }}
              >
                Upload an image to create a meme
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default MemeUpload;
